import { makeAutoObservable } from "mobx";

export class ConstantData {
    
    // 1. 公告栏开关（默认关闭）
    bulletiBoardSwitch = false

    constructor() {
        makeAutoObservable(this)
    }

    // 2. 设置公告栏的值
    setBulletiBoardSwitch(value) {
        this.bulletiBoardSwitch = value
    }

}