import { useEffect } from 'react';
import { FREE_ACCOUNT, getToken, settToken, TOKEN } from '../utils/tools';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CheckLoginInterface } from '../utils/request';

// 路由守卫
export const AuthGuard = ({ children, auth, title }) => {
    const navigate = useNavigate();
    const token = getToken(TOKEN)

    useEffect(() => {
        console.log('auth--->', auth)
        console.log('title--->', title)
        if (token === '') {
            navigate("/login");
        } else {
            if (auth) {
                console.log('title--->', title)
                CheckLoginInterface()
                    .then((res) => {
                        if (res.success) {
                            // 继续渲染子组件
                            console.log('参数-->', res.data)
                            settToken(FREE_ACCOUNT, res.data.free_account)
                        } else {
                            message.error(res.error);
                            navigate("/login");
                        }
                    })
                    .catch(e => {
                        message.error('登录验证失败');
                        navigate("/login");
                    });
            }
        }
    }, [navigate, token, auth, title]);

    return children;
};
