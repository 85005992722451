// ======================支付方式logo========================
import alipay from '../images/payway/zfb.png'
import wechat from '../images/payway/wechat.jpeg'

import panda_logo from '../images/panda.png';
import vertifyPicture from '../images/codeVerifyImage/picture1.jpg';
export const getVertifyPicture = () => vertifyPicture

export const Alipay = alipay
export const Wechat = wechat

export const wechat_appid = 'wx281c6e6b6cffe1b1'


export const ScreenWidth = window.innerWidth;

export const logo = panda_logo
export const SYSTEM_NAME = '专业问答系统'
export const USERNAME = 'ai_chat_user'
export const TOKEN = 'ai_chat_jwt_token'
export const REFRESH_TOKEN = 'ai_chat_refresh_token'
export const FREE_ACCOUNT = 'ai_chat_free_account'
export const ALL_ACCOUNT = 'ai_chat_all_account'
export const AI_RULE_READER = 'ai_chat_read_rule'
export const timeout = 1200000


export const getToken = (key) => localStorage.getItem(key)
export const settToken = (key, value) => localStorage.setItem(key, value)
export const removeAllTokens = () => {
    window.localStorage.removeItem(USERNAME)
    window.localStorage.removeItem(TOKEN)
    window.localStorage.removeItem(REFRESH_TOKEN)
    window.localStorage.removeItem(FREE_ACCOUNT)
    window.localStorage.removeItem(ALL_ACCOUNT)
    window.localStorage.removeItem(AI_RULE_READER)
}

export const divideWithDecimal = (num1, num2) => {
    // 检查除数是否为0，避免除以0错误
    if (parseInt(num2) === 0) {
        console.error("除数不能为0");
        return 0; // 或者抛出错误，取决于你的需求
    }

    // 使用除法运算符得到结果，再使用 toFixed 保留2位小数
    const result = (parseInt(num1) / parseInt(num2) * 100).toFixed(0);

    // 将结果转换为数字类型并返回
    return parseFloat(result);
}


// const server_ip_address = '43.132.147.11'
const server_ip_address = 'www.szyai.com'
export const localURL = `https://${server_ip_address}/api`
export const socket_wechat_url = `wss://${server_ip_address}/wss/wechat/qr/`
export const socket_midjourney_url = `wss://${server_ip_address}/wss/websocket/midjourney/`

// 屏幕阈值
export const ScreenMinSize = 768


export const getFreeMoney = () => {
    const accountBalance = getToken(FREE_ACCOUNT) / 7000;
    return accountBalance.toFixed(1); // 保留一位小数
}