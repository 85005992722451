import {
    Button,
    Col,
    Form,
    Input,
    Row,
    message
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { RegisterCode, ThirdUserRegister, WeChatPublicRegister } from '../utils/request';
import { FREE_ACCOUNT, TOKEN, USERNAME, settToken } from '../utils/tools';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function NewThirdUserRegister() {
    var navigate = useNavigate()
    const [form] = Form.useForm();
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    // 获取查询字符串参数
    const ticket = searchParams.get('ticket');
    const uuid = searchParams.get('uuid');

    const wechatScanQRLogin = async (values, ticket) => {
        values['ticket'] = ticket
        const reponse = await ThirdUserRegister({ msg: values })
        if (reponse.success) {
            message.success('信息完善成功')
            settToken(USERNAME, reponse.data.username)
            settToken(TOKEN, reponse.data.token)
            settToken(FREE_ACCOUNT, reponse.data.free_account)
            // 3、登陆成功进行路由跳转 history replace模式
            const timer = setTimeout(() => {
                navigate('/index/chat', { replace: true })
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            message.error(reponse.error)
        }
    }

    const wechatPublicLogin = async (values, uuid) => {
        values['uuid'] = uuid
        const reponse = await WeChatPublicRegister({ msg: values })
        if (reponse.success) {
            message.success('信息完善成功')
            settToken(USERNAME, reponse.data.username)
            settToken(TOKEN, reponse.data.token)
            settToken(FREE_ACCOUNT, reponse.data.free_account)
            // 3、登陆成功进行路由跳转 history replace模式
            const timer = setTimeout(() => {
                navigate('/index/chat', { replace: true })
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            message.error(reponse.error)
        }
    }

    const onFinish = (values) => {
        if (ticket !== null && ticket.trim !== '') {
            wechatScanQRLogin(values, ticket)
        } else if (uuid !== null && uuid.trim !== '') {
            wechatPublicLogin(values, uuid)
        } else {
            message.info('uuid ticket 不能都为空')
        }

    };

    function getCode() {
        // 1、获取当前用户使用的手机号码！
        var tel = form.getFieldValue('user_telephone')
        // 2、发送短信验证码的请求
        RegisterCode({ msg: tel }).then(res => {
            if (res.code === 200) {
                message.success('短信息发送成功，请注意查收')
                form.setFieldValue('code', res.data.msgCode)
            } else {
                message.error(res.error)
            }

        }).catch(e => {
            message.error(e)
        })
    }

    useEffect(() => {
        if ((ticket === null || ticket.trim === '') && (uuid === null || uuid.trim === '')) {
            message.error('两者为空！')
            navigate('/login', { replace: true })
        }
    }, [ticket, navigate, uuid])


    const [countdown, setCountdown] = useState(0);  // 计时器

    // Function to handle button click
    const handleButtonClick = () => {
        form.validateFields(['user_telephone']).then(getCode).catch(error => { console.log(error) });
    };

    // 计时器
    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);


    return (
        <div align='center' style={{ marginTop: '6%' }}>
            <div style={{ fontSize: '30px', fontWeight: 'bold', textAlign: 'center', marginBottom: 22 }}>
                新用户信息完善
            </div>
            <Form
                {...formItemLayout}
                form={form}
                onFinish={onFinish}
                className='layout-register-or-forget-password-form'
                scrollToFirstError
            >

                <Form.Item
                    name="user_telephone"
                    label="手机号"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请输入手机号码!'
                        },
                        {
                            pattern: /^1\d{10}$/,
                            message: '手机号格式错误！',
                        },
                    ]}
                >
                    <Input style={{ width: '100%', height: 40 }} placeholder='请输入手机号码' />
                </Form.Item>

                <Form.Item label="验证码">
                    <Row gutter={8}>
                        <Col span={14}>
                            <Form.Item
                                name="code"
                                noStyle
                                rules={[{ required: true, message: '请输入短信验证码!' }]}
                                hasFeedback
                            >
                                <Input style={{ height: 40 }} />
                            </Form.Item>
                        </Col>
                        <Col offset={2} span={6}>
                            <Button
                                onClick={handleButtonClick}
                                style={{ height: 40 }}
                                disabled={countdown > 0}  // Disable the button if countdown is active
                            >
                                {countdown > 0 ? `${countdown}秒后重试` : '获取验证码'}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button block type="primary" htmlType="submit" style={{ height: 40 }}>
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};