import axios from "axios";
import { TOKEN, getToken, localURL, removeAllTokens, timeout } from "./tools";
// @ts-ignore  //这个东东就是这样的，过滤报错，进度条
import { message } from "antd";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';


// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // @ts-ignore
    if (config.headers.Authorization === null || config.headers.Authorization === undefined) {
      config.headers.Authorization = `Bearer ${getToken(TOKEN)}`
    }
    if (config.headers['Content-Type'] === null || config.headers['Content-Type'] === undefined) {
      config.headers['Content-Type'] = 'application/json'
    } else {
      console.log('config headers--->', config.headers['Content-Type'])
    }
    config.withCredentials = true  // sessionid精髓中的精髓
    // true是本地环境
    if (config.baseURL === null || config.baseURL === undefined) {
      config.baseURL = localURL
    }
    config.timeout = timeout
    NProgress.start()
    return config
  },
  (error) => {
    // 对请求错误做些什么
    message.error('请求错误，请重试+1')
    return Promise.reject(error)
  }
)

// 响应拦截
axios.interceptors.response.use(
  function (response) {
    NProgress.done()
    const currentUrl = window.location.hash;
    if (currentUrl !== '' && currentUrl !== '#/login' && response.hasOwnProperty('data')) {
      console.log(response.data)
      if (parseInt(response.data.code) === 403) {
        // 3秒后跳转到登录界面
        message.error(response.data.error)
        removeAllTokens()
        setTimeout(function () {
          window.location.href = '/';
        }, 1000); // 1000毫秒
      }
    }
    return response
  },
  async error => {
    // 响应错误做什么
    NProgress.done()
    // const originalRequest = error.config;
    // // 检查错误是否是由于 JWT 失效引起的
    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true; // 标记这是第一次尝试重新获取 token

    //   try {
    //     // 使用 refresh_token 发起请求以获取新的 access_token
    //     const refreshToken = getToken(REFRESH_TOKEN)
    //     const response = await RefreshToken({ refreshToken: refreshToken })

    //     // 将新的 access_token 保存到存储中，同时更新请求头
    //     const { accessToken } = response.data;
    //     api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    //     // 更新原始请求的头部并重新发起请求
    //     originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
    //     return api(originalRequest); // 重新发起原始请求
    //   } catch (refreshError) {
    //     // 处理刷新 token 失败的情况
    //     console.error('Unable to refresh token', refreshError);
    //     // 可能需要重定向到登录页面或进行其他操作
    //     return Promise.reject(refreshError);
    //   }
    // }

    // 返回任何非 JWT 失效错误
    return Promise.reject(error);
  })


export const getRequest = (url, params = {}) => axios.get(url, { params }).then((res) => res.data)
export const postRequest = (url, data = {}, config = {}) => axios.post(url, data, config).then((res) => res.data)
export const putRequest = (url, data = {}) => axios.put(url, data).then((res) => res.data)
export const patchRequest = (url, data = {}) => axios.patch(url, data).then((res) => res.data)
export const deleteRequest = (url, data = {}) => axios.delete(url, data).then((res) => res.data)