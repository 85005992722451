import { makeAutoObservable } from "mobx";

export class ChatInfoList {
    // 默认-1表示用户没有选中会话
    current_chat_session_id = -1
    sessionModelName = null
    sessionName = null

    // 1、对话框中的聊天信息
    current_chat_info_list = []

    store_temp_data = {
        '1': [
            {'id': 1, 'user_id': 1, 'chat_session_id': 1, 'user_question': '画一只狗', 'ai_answer': '抱歉没有这功能', 'info_type': 1},
        ]
    }

    // 会话列表
    // 模型选择：gpt-4 gpt-4 turbo gpt-3.5-turbo
    chat_session_list = []

    // 会话列表 
    // chat_info_list = [
    //     {'id': 1, 'chat_session_id': 1, 'user_question': '画一只狗', 'ai_answer': '抱歉没有这功能', 'user_id': 1}
    // ]

    constructor() {
        makeAutoObservable(this)
    }

    /* =======================================聊天展示信息======================================= */
    listClearAll() {
        this.current_chat_info_list = []
    }
    
    
    listPush(value) {
        this.listPop()
        this.current_chat_info_list.push(value)
        this.store_temp_data[this.current_chat_session_id] = this.current_chat_info_list
    }

    // 临时的user_id为0，因为数据库中的user_id不可能为0
    listPop() {
        let index = this.current_chat_info_list.findIndex(obj => obj.user_id === 0);
        if (index !== -1) {
            this.current_chat_info_list.splice(index, 1)
            this.store_temp_data[this.current_chat_session_id] = this.current_chat_info_list
        }
    }

    setCurrentChatInfoList(value) {
        this.current_chat_info_list = value
    }

    getCurrentChatInfoList() {
        return this.current_chat_info_list
    }

    // 更新对话框中的聊天信息
    listUpdateLastOne(value) {
        this.listPop()
        this.listPush(value)
    }

    /* =======================================左侧会话展示信息======================================= */
    changeSessionId(value) {
        this.current_chat_session_id = value
    }

    setSessionModelName(value) {
        this.sessionModelName = value
    }
    setSessionName(value) {
        this.sessionName = value
    }

    setDefaultValueForSessionId() {
        this.current_chat_session_id = -1
    }

    isEqualSessionId(value) {
        return parseInt(this.current_chat_session_id) === parseInt(value);
    }

    // 两数字是否相等
    isEqualAandB(a, b) {
        return parseInt(a) === parseInt(b);
    }

    // 菜单会话列表  getChatSessionListByUserId post
    setChatSessionList(value) {
        this.chat_session_list = value
    }

    getSessionList() {
        return this.chat_session_list
    }

    getSessionById(id) {
        const index = this.chat_session_list.findIndex(item => this.isEqualAandB(item.id, id));
        console.log('index--->', index)
        if (index !== -1) {
            return this.chat_session_list[index]
        } else {
            return null
        }
    }

    deleteSessionById(idToDelete) {
        const index = this.chat_session_list.findIndex(item => this.isEqualAandB(item.id, idToDelete));
        if (index !== -1) {
            this.chat_session_list.splice(index, 1);
        }
    }

    updateSessionById(data) {
        const index = this.chat_session_list.findIndex(item => this.isEqualAandB(item.id, data.id));
        if (index !== -1) {
            this.chat_session_list.splice(index, 1);
            this.chat_session_list.unshift(data)
        }
    }

    pushToSessionList(value) {
        this.chat_session_list.unshift(value)
    }
      
}