import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { findPayOrder } from '../utils/request';
import { Button, message } from 'antd';

const QRCodeComponent = ({ responseData }) => {
    const dataObj = JSON.parse(responseData.message);
    const [buttonDisable, setButtonDisabble] = useState(false)

    // 从解析后的对象中获取code_url
    const codeUrl = dataObj.code_url;

    const findOrder = async () => {
        try {
            setButtonDisabble(true)
            // 1. 准备数据
            const out_trade_no = responseData.out_trade_no
            const pre_data = {
                'out_trade_no': out_trade_no,
                'pay_type': 2
            }
            console.log(pre_data)
            let res = await findPayOrder(pre_data)
            if (res.success) {
                message.success('支付成功')
                setTimeout(function () {
                    window.location.href = 'https://www.szyai.com/#/index/chat';
                }, 1000); // 1000毫秒
            } else {
                message.error(res.error)
                setButtonDisabble(false)
            }
        } catch (error) {
            console.log('未知错误：', error)
            setButtonDisabble(false)
        }
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0.2rem' }}>
            {/* <h2>微信支付</h2> */}
            {codeUrl ? (
                <QRCode value={codeUrl} size={200} level={"H"} includeMargin={true} />
            ) : (
                <p>二维码加载失败</p>
            )}
            <Button
                style={{
                    width: '10.5rem',
                    // backgroundColor: '#EAF3FF',
                    // color: '#108ee9',
                    marginTop: '0.2rem'
                }}
                type="primary"
                disabled={buttonDisable}
                onClick={findOrder}>我已支付</Button>
        </div>
    );
};

export default QRCodeComponent;
