import { Card, Descriptions } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function PaySuccess() {
    const times = 5  // 设置3秒倒计时
    const times_timer = 5000  // 设置3秒定时器
    const [countdown, setCountdown] = useState(0);  // 计时器
    var navigate = useNavigate();
    const location = useLocation();
    // 从参数中获取得到！
    const searchParams = new URLSearchParams(location.search);
    const searchDict = {};
    for (const [key, value] of searchParams.entries()) {
        searchDict[key] = value;
    }


    useEffect(() => {
        console.log(searchDict)
        setCountdown(times);
        const timer = setTimeout(() => {
            navigate('/index/chat', { replace: true })
        }, times_timer);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [])

    // 计时器
    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    return (
        <div className="pay-success" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5%' }}>
            <Card className="card" style={{ width: 600 }} title="订单信息">
                <Descriptions bordered column={1}>
                    <Descriptions.Item label="订单号" >{searchDict.out_trade_no}</Descriptions.Item>
                    <Descriptions.Item label="交易号" >{searchDict.trade_no}</Descriptions.Item>
                    <Descriptions.Item label="交易金额">￥ {searchDict.total_amount}</Descriptions.Item>
                    <Descriptions.Item label="付款时间">{searchDict.timestamp}</Descriptions.Item>
                </Descriptions>
                <div className="study" style={{ textAlign: 'center', marginTop: 20 }}>
                    <h3>
                        <Link to="/index/chat">{`${countdown}秒后自动跳转，点击立即跳转……`}</Link>
                    </h3>
                </div>
            </Card>
        </div>
    )
}
