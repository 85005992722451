import { ChatApp } from '../components/chatApp';
import { SearchImage } from '../components/searchImage';
import { MenuApp } from '../components/menuApp';

import { LoginSurper } from '../components/loginSurper';
import { Register } from '../components/register';
import RetrievePassword from '../components/RetrievePassword';
import { UserCenter } from '../components/UserCenter';
import PayCenter from '../components/PayCenter';
import PaySuccess from '../components/PaySuccess';
import NewThirdUserRegister from '../components/NewThirdUserRegister';
import WechatPublicLogin from '../components/WechatPublicLogin';

export const routers = [
  {
    title: '注册',
    path: '/register',
    element: <Register />,
    auth: false,
  },
  {
    title: '登录',
    path: '/login',
    element: <LoginSurper />,
    auth: false,
  },
  {
    title: '密码找回',
    path: '/findPassword',
    auth: false,
    element: <RetrievePassword />
  },
  {
    title: '完善资料',
    path: '/newThirdUserRegister',
    auth: false,
    element: <NewThirdUserRegister />
  },
  {
    title: '微信公众号授权登录',
    path: '/wechat/public/login',
    element: <WechatPublicLogin />,
    auth: false,
  },
  {
    title: '首页',
    path: '/index/*',
    element: <MenuApp />,
    auth: false,
    children: [
      {
        title: '聊天',
        path: 'chat',
        element: <ChatApp />,
        auth: false,
      },
      {
        title: '画画',
        path: 'image',
        element: <SearchImage />,
        auth: false,
      },
      {
        title: '个人中心',
        path: 'user',
        element: <UserCenter />,
        auth: false,
      },
      {
        title: '充值中心',
        path: 'paycenter',
        element: <PayCenter />,
        auth: true,
      },
      {
        title: '对话',
        path: '*',
        element: <ChatApp />,
        auth: false,
      },
    ]
  },
  {
    title: '支付成功',
    path: '/pay/success',
    element: <PaySuccess />,
    auth: false,
  },
  {
    title: '登录',
    path: '*',
    auth: false,
    element: <LoginSurper />,
  },
];
