import {
    Form,
    Input,
    message,
    Button,
    Col,
    Modal,
    Row,
    Layout,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { changeTelephone, getCode, getUserInfo, updatePwd } from '../utils/request';
import { Link } from 'react-router-dom';
import { ScreenMinSize, ScreenWidth } from '../utils/tools';

var { Content } = Layout;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export function UserCenter() {
    const [countdown, setCountdown] = useState(0);  // 计时器
    const codeStyleSize = ScreenWidth < ScreenMinSize ? '14' : '12'
    const [form] = Form.useForm();
    const [modalForm] = Form.useForm();
    const [modalFlag, setModalFlag] = useState(false)
    const show = () => {
        setModalFlag(true);
    };
    const hide = () => {
        setModalFlag(false);
    };

    const onFinish = async (values) => {
        console.log('修改密码--->', values)
        var res = await updatePwd({ 'data': values['user_password'] })
        if (res.success) {
            message.success(res.data)
        } else {
            message.error(res.error)
        }
    };

    function getCodeChangeTelephone() {
        // 1、获取当前用户使用的手机号码！
        var tel = modalForm.getFieldValue('new_user_telephone')
        // 2、发送短信验证码的请求
        getCode({ 'msg': tel }).then(res => {
            if (res.success) {
                setCountdown(60);
                message.success('短信息发送成功，请注意查收')
                // modalForm.setFieldValue('code', res.data.msgCode)
            } else {
                message.error(res.error)
            }
        }).catch(e => {
            message.error(e)
        })
    }

    useEffect(() => {
        // 向后端发送请求获取用户的基本信息
        getUserInfo().then(res => {
            console.log('后端获取的数据--->', res)
            if (res.success) {
                form.setFieldValue('user_telephone', res.data.user_telephone)
                form.setFieldValue('user_account', res.data.user_account)
                form.setFieldValue('user_password', res.data.user_password)
                form.setFieldValue('free_account', res.data.user_account_balance)
            } else {
                message.error(res.error)
            }
        }).catch(e => {
            message.error('未知错误！', e)
        })
        // eslint-disable-next-line
    }, [])

    // 计时器
    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [countdown]);

    return (
        <Layout align='center' style={{ backgroundColor: 'white', height: '100vh' }}>
            <Content style={{ marginTop: '10%' }}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    className='layout-register-or-forget-password-form'
                    scrollToFirstError
                >

                    <Form.Item
                        name="user_account"
                        label="用户名"
                        rules={[
                            {
                                required: true,
                                message: '请输入用户名!',
                            },
                            {
                                min: 4,
                                message: '用户名长度至少为4',
                            }
                        ]}
                        hasFeedback
                    >
                        <Input placeholder='请输入用户名' style={{ height: 40 }} disabled={true} />
                    </Form.Item>
                    <Form.Item
                        name="user_password"
                        label="密码"
                        rules={[
                            {
                                required: true,
                                message: '请输入密码！',
                            },
                            {
                                min: 4,
                                message: '密码长度至少为4',
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder='请输入密码' style={{ height: 40 }} />
                    </Form.Item>
                    <Form.Item
                        label="手机号"
                    >
                        <Row gutter={8}>
                            <Col span={17}>
                                <Form.Item name="user_telephone">
                                    <Input style={{ width: '100%', height: 40 }} placeholder='请输入手机号码' disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={6}>
                                <Form.Item>
                                    <Link onClick={show}>换绑手机号</Link>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>

                    {/* =========================更换手机号码的弹窗 start========================= */}
                    <Modal
                        title='更换手机号码'
                        style={{ marginTop: '8%' }}
                        destroyOnClose
                        open={modalFlag}
                        width={400}
                        onCancel={hide}
                        maskClosable={false}
                        onOk={() => modalForm.submit()}
                    >
                        <Form
                            {...formItemLayout}
                            form={modalForm}
                            preserve={false}
                            onFinish={async (values) => {
                                console.log('更换手机号码!', values)
                                var res = await changeTelephone(values)
                                if (res.success) {
                                    message.success(res.data)
                                    hide()
                                    // 关闭弹窗的同时将，用户基本资料中的数据修改
                                    form.setFieldValue('user_telephone', values['new_user_telephone'])
                                } else {
                                    message.error(res.error)
                                }
                            }}
                        >
                            <Form.Item
                                name="new_user_telephone"
                                label="新手机号"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入手机号码!'
                                    },
                                    {
                                        pattern: /^1\d{10}$/,
                                        message: '手机号格式错误！',
                                    },
                                ]}
                            >
                                <Input style={{ width: '100%', height: 40 }} placeholder='请输入手机号码' />
                            </Form.Item>

                            <Form.Item label="验证码">
                                <Row gutter={8}>
                                    <Col span={codeStyleSize}>
                                        <Form.Item
                                            name="code"
                                            noStyle
                                            rules={[{ required: true, message: '请输入短信验证码!' }]}
                                            hasFeedback
                                        >
                                            <Input style={{ height: 40 }} />
                                        </Form.Item>
                                    </Col>
                                    <Col offset={1} span={6}>
                                        <Button
                                            onClick={() => {
                                                modalForm.validateFields(['new_user_telephone']).then(getCodeChangeTelephone).catch(error => { console.log(error) });
                                            }}
                                            style={{ height: 40 }}
                                            disabled={countdown > 0}>
                                            {countdown > 0 ? `${countdown}秒后重试` : '获取验证码'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Modal>
                    {/* =========================更换手机号码的弹窗 end========================= */}
                    <Form.Item {...tailFormItemLayout}>
                        <Button block type="primary" htmlType="submit" style={{ height: 40 }}>
                            修改密码
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </Layout >
    );
};