import { Route, HashRouter as Router, Routes } from 'react-router-dom';
import { routers } from './routers';
import { AuthGuard } from './routers/AuthGard';

// 这是宿舍电脑

const App = () => {
  // write the JSX in the brackets
  // dhsajkhjdksahjd
  return (
    <Router>
      <Routes>
        {routers.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={<AuthGuard auth={route.auth} title={route.title}>{route.element}</AuthGuard>}
          >
            {route.children?.map(child => (
              <Route
                key={`${route.path}${child.path}`}
                path={child.path}
                element={child.element}
              />
            ))}
          </Route>
        ))}
      </Routes>
    </Router>
  );
}


export default App

