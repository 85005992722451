import { makeAutoObservable } from "mobx";

export class ChatImageStore {
    imageResponseData = []  // discord.py响应的数据

    theNextLegImagesList = []  // 存储theNextLeg的响应数据

    imageBalance = 0  // 存储用户的剩余绘画次数

    constructor() {
        makeAutoObservable(this)
    }

    // 修改当前剩余次数
    setImageBalance(value) {
        this.imageBalance = value
    }


    // ===================================Discord API===================================
    // 改变整个列表
    changeImageResponseData(listData) {
        this.imageResponseData = listData
    }

    // 增加
    insertImageData(data) {
        this.imageResponseData.unshift(data)
    }

    // 删除
    removeImageDataByTriggerId(id) {
        this.imageResponseData = this.imageResponseData.filter(obj => {
            return obj.id !== id
        });
    }

    // ===================================TheNextLeg API===================================
    // 首位插入
    insertTheNextLegImageData(data) {
        this.theNextLegImagesList.unshift(data)
    }

    // 改变整个列表
    changeTheNextLegImagesList(listData) {
        this.theNextLegImagesList = listData
    }

    // 更新第一个
    updateTheNextLegFirstImageData(imageData) {
        if (this.theNextLegImagesList.length > 0) {
            this.theNextLegImagesList[0] = imageData;
        } else {
            // 如果列表为空，可以选择添加新数据或者不做任何操作
            this.theNextLegImagesList.unshift(imageData);
        }
    }

    // 删除指定元素
    removeOneTheNextLegImageData(originatingMessageId) {
        this.theNextLegImagesList = this.theNextLegImagesList.filter(obj => {
            return obj.response.originatingMessageId !== originatingMessageId
        });
    }
}