import { message } from "antd";
import { USER_IMAGINE_NOT_TIMES, USER_NEED_TO_LOGIN_CODES } from "./constant";
import { TOKEN, getToken, removeAllTokens } from "./tools";
const JSONbig = require('json-bigint')({ storeAsString: true });



class WebSocketInstance {
    constructor() {
        this.socket = null;
        this.messageCallback = null; // 回调函数用于处理接收到的消息
        this.token = null; // 添加一个变量来存储token
    }

    setCallback(callback) {
        this.messageCallback = callback;
    }

    connect(url) {
        // console.log('用户连接--->', url)
        if (url !== undefined) {
            // 从环境变量获取token
            this.token = getToken(TOKEN)
            this.socket = new WebSocket(url, [this.token]);
        }
        this.socket.onopen = () => {
            console.log('WebSocket连接已建立');
        };

        this.socket.onmessage = (event) => {
            if (this.messageCallback) {
                const data = JSONbig.parse(event.data);
                this.messageCallback(data); // 调用回调函数处理接收到的消息
            }
        };

        this.socket.onclose = (event) => {
            if (event.code !== undefined) {
                const response_code = event.code
                console.log('close code = ', response_code)
                if (response_code === USER_IMAGINE_NOT_TIMES) {
                    message.error('用户无绘画次数')
                } else if(USER_NEED_TO_LOGIN_CODES.includes(response_code)) {
                    message.error('登陆过期，正在跳转登陆界面……')
                    // 设置延迟跳转
                    setTimeout(() => {
                        window.location.href ='/'
                        removeAllTokens()
                    }, 1000); // 3秒后跳转
                }
            }
        };
    }

    disconnect() {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
    }

    // 使用箭头函数来确保回调中的this指向类的实例
    waitForWebSocketConnection = (callback) => {
        if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
            // 如果WebSocket尚未连接或已经关闭，则先建立连接
            this.connect();
        }

        if (this.socket.readyState === WebSocket.OPEN) {
            // WebSocket连接已经打开，执行后续操作
            callback();
        } else if (this.socket.readyState === WebSocket.CONNECTING) {
            // WebSocket连接正在建立中，等待一小段时间后再次调用等待函数
            setTimeout(() => {
                this.waitForWebSocketConnection(callback);
            }, 100); // 可以根据实际情况调整等待时间
        } else {
            // WebSocket连接未建立或已经关闭
            console.log('WebSocket连接未建立或已关闭');
        }
    };

    sendMessage = (message) => {
        this.waitForWebSocketConnection(() => {
            this.socket.send(message);
        });
    };
}

const instance = new WebSocketInstance();
export default instance;
