import {
    LockOutlined,
    MobileOutlined,
    UserOutlined
} from '@ant-design/icons';
import {
    LoginForm,
    ProConfigProvider,
    ProFormCaptcha,
    ProFormCheckbox,
    ProFormText,
} from '@ant-design/pro-components';
import { Flex, Modal, Tabs, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
// import WechatLogin from '../components/WechatLogin';
import { postRequest } from "../utils/api";
import { NOT_EXIST_USER, USER_NOT_READER_RULE } from '../utils/constant';
import { CheckLoginInterface, LogoutApp, WeChatPublicLoginRequest } from '../utils/request';
import { AI_RULE_READER, ALL_ACCOUNT, FREE_ACCOUNT, TOKEN, USERNAME, getToken, removeAllTokens, settToken } from '../utils/tools';
import PoliceComponent from './PoliceComponent';
import { useStore } from '../store';

// const iconStyles = {
//     marginInlineStart: '16px',
//     color: 'rgba(0, 0, 0, 0.2)',
//     fontSize: '24px',
//     verticalAlign: 'middle',
//     cursor: 'pointer',
// };

export const LoginSurper = observer(() => {
    const navigate = useNavigate();
    const { constantStore } = useStore()
    const [form] = useForm();
    const [loginType, setLoginType] = useState('account');

    const toRegister = () => {
        navigate('/register', { replace: true })
    }
    const findPassword = () => {
        navigate('/findPassword', { replace: true })
    }

    // const [captchaUrl, setCaptchaUrl] = useState('');
    const onSubmit = (values) => {
        console.log('Received values of form: ', loginType);
        postRequest('/login/', { msg: values, loginType: loginType === 'account' ? 1 : 0 }).then(res => {
            // 2、校验是否登陆成功
            console.log('后端响应：', res)
            if (res.success) {
                // 2.1 window.localStorage.setItem('ai_chat_user', res.data.username)
                settToken(USERNAME, res.data.username)
                settToken(TOKEN, res.data.token)
                settToken(FREE_ACCOUNT, res.data.free_account)
                settToken(ALL_ACCOUNT, res.data.all_account)
                // 2.2 用户是否已阅读
                settToken(AI_RULE_READER, USER_NOT_READER_RULE)
                // 2.2 开题公告栏弹窗
                constantStore.setBulletiBoardSwitch(true)
                // 3、登陆成功进行路由跳转 history replace模式
                const timer = setTimeout(() => {
                    navigate('/index/chat', { replace: true })
                }, 1000);
                return () => clearTimeout(timer);
            } else {
                message.error(res.error)
            }
        }).catch(reason => {
            message.error(reason)
        })
    }
    const onFocus = () => {
        console.log("----------");
    };

    function getCode() {
        // 1、获取当前用户使用的手机号码！
        let tel = form.getFieldValue('telephone')
        // 2、发送短信验证码的请求
        postRequest('/getLoginCode/', { msg: tel }).then(res => {
            console.log('注册响应：res=', res)
            if (res.code === 200) {
                // 3、将短信验证码放入code输入框中
                message.success('短信息发送成功，请注意查收')
                // form.setFieldValue('code', res.data.msgCode)
            } else {
                message.error(res.error)
            }

        }).catch(e => {
            message.error(e)
        })
    }

    const [showDialog, setShowDialog] = useState(false);  // 是否显示对话框
    const handleCancel = () => {
        // 发送logout请求
        LogoutApp().then((res) => {
            if (res.success) {
                // 清空localstorage
                removeAllTokens()
                // 取消操作，关闭对话框
                setShowDialog(false);
            }
        })
    };
    const handleConfirm = () => {
        console.log('---点击确认---')
        navigate('/index/chat', { replace: true })
    }

    // const [showWechatModalVisible, setShowWechatModalVisible] = useState(false)

    // const OpenThirdLoginModal = (type) => {
    //     if (type === 'wechat') {
    //         // sendMessage()
    //         setShowWechatModalVisible(true)
    //     }
    // }
    // const CloseThirdLoginModal = () => {
    //     // closeConnect()
    //     setShowWechatModalVisible(false)
    // }


    // 获取 URL 参数的函数
    function getUrlParameter(name) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    }

    // 使用示例，获取 code 参数的值
    const wechat_auth_code = getUrlParameter('code');
    console.log('code value:', wechat_auth_code);

    const fetchData = async (wechat_auth_code) => {
        // console.log('code--->', wechat_auth_code)
        const res = await WeChatPublicLoginRequest({ 'code': wechat_auth_code });
        if (res.success) {
            // 1.1 登录成功
            message.success('登录成功，正在跳转')
            // 1.2 设置存储
            settToken(USERNAME, res.data.username)
            settToken(TOKEN, res.data.token)
            settToken(FREE_ACCOUNT, res.data.free_account)
            settToken(ALL_ACCOUNT, res.data.all_account)
            // 1.3 路由跳转
            const timer = setTimeout(() => {
                navigate('/index/chat', { replace: true })
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            // console.error('1', res.uuid)
            // console.error('2', res.code)
            // console.error('3', res.error)
            if (res.code === NOT_EXIST_USER) {
                // 2.1 用户注册界面
                console.error('请注册：', res.code)
                navigate(`/newThirdUserRegister?uuid=${res.uuid}`, { replace: true })
            } else {
                //message.error(res.error)
            }
        }
    }

    if (wechat_auth_code !== null && wechat_auth_code !== '') {
        fetchData(wechat_auth_code)
    }

    useEffect(() => {
        const auth_token = getToken(TOKEN)
        if (wechat_auth_code === null && (auth_token !== null || auth_token !== '')) {
            CheckLoginInterface().then((res) => {
                if (res.success) {
                    // 询问用户重新登录还是跳转主页！
                    setShowDialog(true)
                } else {
                    // 清空localstorage
                    console.error(res)
                    removeAllTokens()
                }
            })
        }
    }, [navigate, wechat_auth_code])


    return (
        <ProConfigProvider hashed={false}>
            {/* {contextHolder} */}
            <div style={{ backgroundColor: 'white', marginTop: '5%' }}>
                <LoginForm
                    className='login-form'
                    onFinish={onSubmit}
                    onFocus={onFocus}
                    form={form}
                    title={
                        <div style={{ fontSize: '30px', fontWeight: 'bold', textAlign: 'center', marginBottom: 18 }}>
                            登录界面
                        </div>
                    }
                    actions={
                        // <Row justify={'space-evenly'} align={'middle'}>
                        //     {/* <Col span={8}>
                        //         <span style={{ color: '#CCC', fontWeight: 'normal' }}>
                        //             其他登录方式
                        //         </span>
                        //     </Col> */}
                        //     <Col span={10} pull={2} >
                        //         {/* <AlipayCircleOutlined style={{ ...iconStyles, color: '#1677FF' }} /> */}
                        //         {/* <TaobaoCircleOutlined style={{ ...iconStyles, color: '#FF6A10' }} /> */}
                        //         {/* <WechatOutlined style={{ ...iconStyles, color: '#44b549' }} */}
                        //         {/* // onClick={() => OpenThirdLoginModal('wechat')} */}
                        //         {/* /> */}
                        //         <PoliceComponent />
                        //     </Col>
                        //     <Col span={6}>
                        //         <span style={{ fontWeight: 'normal' }}>
                        //             <Link to='/register' onClick={toRegister} className='no-account'>没有账户</Link>
                        //         </span>
                        //     </Col>
                        // </Row>
                        <Flex justify='space-between' align='center'>
                            <PoliceComponent />
                            {/* <span style={{ fontWeight: 'normal' }}>
                                <Link to='/register' onClick={toRegister} className='no-account'>没有账户</Link>
                            </span> */}
                        </Flex>
                    }
                >
                    <Modal
                        open={showDialog}
                        onOk={handleConfirm}
                        onCancel={handleCancel}
                        width={300}
                        okText="确认前往"
                        cancelText="重新登录"
                        closable={false}
                        maskClosable={false}
                    >
                        <h3>检测到你已登录，是否前往主页？</h3>
                    </Modal>

                    {/* {showWechatModalVisible ? (
                        <WechatLogin visible={showWechatModalVisible} onClose={CloseThirdLoginModal} />
                    ) : null} */}


                    <Tabs
                        centered
                        activeKey={loginType}
                        onChange={(activeKey) => setLoginType(activeKey)}
                    >
                        <Tabs.TabPane key={'account'} tab={'账号密码登录'} />
                        <Tabs.TabPane key={'phone'} tab={'手机号登录'} />
                    </Tabs>
                    {loginType === 'account' && (
                        <>
                            <ProFormText
                                name="username"
                                fieldProps={{
                                    size: 'large',
                                    prefix: <UserOutlined className={'prefixIcon'} />,
                                }}
                                placeholder={'用户名: admin or user'}
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入用户名!',
                                    },
                                ]}
                            />
                            <ProFormText.Password
                                name="password"
                                fieldProps={{
                                    size: 'large',
                                    prefix: <LockOutlined className={'prefixIcon'} />,
                                }}
                                placeholder={'密码: password'}
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入密码！',
                                    },
                                ]}
                            />
                        </>
                    )}
                    {loginType === 'phone' && (
                        <>
                            <ProFormText
                                fieldProps={{
                                    size: 'large',
                                    prefix: <MobileOutlined className={'prefixIcon'} />,
                                }}
                                name="telephone"
                                placeholder={'手机号'}
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入手机号！',
                                    },
                                    {
                                        pattern: /^1\d{10}$/,
                                        message: '手机号格式错误！',
                                    },
                                ]}
                            />
                            <ProFormCaptcha
                                fieldProps={{
                                    size: 'large',
                                    prefix: <LockOutlined className={'prefixIcon'} />,
                                }}
                                captchaProps={{
                                    size: 'large',
                                }}
                                placeholder={'请输入验证码'}
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入验证码！',
                                    },
                                ]}
                                phoneName='telephone'  // 校验手机号必须通过，否则不进行按钮点击
                                countDown={60}
                                captchaTextRender={(timing, count) => {
                                    if (timing) {
                                        return `${count} ${'秒后重试'}`;
                                    }
                                    return '获取验证码';
                                }}
                                onGetCaptcha={getCode}
                            />
                        </>
                    )}
                    <div
                        style={{
                            marginBlockEnd: 20,
                        }}
                    >
                        <ProFormCheckbox noStyle name="autoLogin">
                            <Flex gap={112}>
                                <span>
                                    自动登录
                                </span>
                                <Flex gap={'middle'}>
                                    <Link to='/findPassword' onClick={findPassword}>忘记密码</Link>
                                    <Link to='/register' onClick={toRegister}>没有账户</Link>
                                </Flex>
                            </Flex>
                        </ProFormCheckbox>
                    </div>
                </LoginForm>
            </div>
        </ProConfigProvider>
    );
})