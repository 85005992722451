import { observer } from "mobx-react";
import React, { useState } from 'react';
import { Modal, Button, Typography, Flex, Tooltip } from 'antd';
import { useStore } from "../store";
import { AI_RULE_READER, getToken, settToken } from "../utils/tools";
import { USER_HAD_READER_RULE, USER_NOT_READER_RULE } from "../utils/constant";

const email_address = '15803854@qq.com'

export const BulletiBoard = observer(() => {
    const { constantStore } = useStore()
    const [canConfirm, setCanConfirm] = useState(false);
    const handleScroll = (e) => {
        const real_height = e.target.clientHeight
        const calculate_height = Math.ceil(e.target.scrollHeight - e.target.scrollTop)
        const bottom = calculate_height - real_height <= 2;
        setCanConfirm(bottom);
    };
    const handleOk = () => {
        constantStore.setBulletiBoardSwitch(false)
        setCanConfirm(false)
        // 改变localstorage
        const token_flag = parseInt(getToken(AI_RULE_READER))
        if (token_flag === USER_NOT_READER_RULE) {
            // 用户已阅读规则
            settToken(AI_RULE_READER, USER_HAD_READER_RULE)
        }
    };

    const isOpen = () => {
        const token_flag = parseInt(getToken(AI_RULE_READER))
        // 1.1 用户未阅读规则，打开Modal
        if (token_flag === USER_NOT_READER_RULE) {
            return true
        }
        // 1.2 用户主动打开规则
        return constantStore.bulletiBoardSwitch
    }
    return (
        <Modal
            open={isOpen()}
            centered={true}
            closeIcon={false}
            destroyOnClose
            maskClosable={false}
            footer={[
                <Flex justify="center" vertical align="center" gap={'.5rem'}>
                    <Tooltip placement="top" title={canConfirm ? '' : '请您认真阅读《规则手册》到末尾'}>
                        <Button
                            type="primary"
                            onClick={handleOk}
                            disabled={!canConfirm} // 按钮是否可点击依赖于canConfirm状态
                            style={{
                                padding: '0px 16px',
                                fontSize: '14px',
                                borderRadius: 0,
                                color: '#f1f1f1',
                                backgroundColor: canConfirm ? "#18A058" : "#A9A9A9",
                            }}
                        >
                            <span style={{ fontSize: '1rem' }}>
                                我已经知悉并愿意遵守上述的相关规则
                            </span>
                        </Button>
                    </Tooltip>
                    <div style={{ fontSize: '.75rem', lineHeight: '1rem' }}>
                        <span style={{ color: "red" }}>*</span>
                        <span
                            style={{ color: "#737373" }}
                        >
                            点击该按钮即代表您已完全知悉并愿意遵守AI 新视界的相关协议及规则，系统会为您跳转⾄使⽤界⾯
                        </span>
                    </div>
                </Flex>
            ]}
            width={'40rem'}
            style={{ margin: '0 10px' }}
        >
            <Typography
                style={{
                    height: '80vh',
                    overflow: 'auto',
                    maxHeight: '600px',
                    padding: '0rem 0.5rem'
                }}
                className="scrollable-content"
                onScroll={handleScroll} // 添加滚动监听
            >
                <Flex justify="center">
                    <span style={{ margin: 0, fontSize: '1.75rem', fontWeight: 'bold' }}>AI 新视界规则手册</span>
                </Flex>
                <br />
                欢迎使用 AI 新视界
                <br />
                <br />
                我们工具在内测阶段仅供购课同学练习使用，在内测期间烦请您遵守相关法律法规。AI 新视界严禁对话、上传、生成制作含有损害国家形象及社会秩序的内容，如：
                <br />
                <br />
                党和国家领导人以及具有政治意义的公众人物图片；<br />
                宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；<br />
                煽动民族仇恨、民族歧视，破坏民族团结的；<br />
                破坏国家宗教政策，生成邪教和封建迷信的；<br />
                淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪图片；<br />
                煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；<br />
                歪曲、丑化、亵渎、否定英雄烈士及其事迹、精神；<br />
                以贬损、玷污、焚烧、涂划、践踏、篡改等方式，侮辱、恶搞、歪曲、丑化国旗、国歌、国徽、人民币、军旗、军歌、军徽等具有特殊含义的象征、标志、音效的；
                <br />
                含有法律法规禁止的其他涉及时政的有害信息。<br />
                <br />
                如果您不小心生成上述内容，请及时联系我们处理或自行删除。我们将对违规同学的内容和个人信息备案，采取电话提醒、限制功能使用、封禁账号等处罚，严重者移送司法机关处理。
                <br />
                <br />
                您知悉并理解，AI 新视界工具是依托大模型技术提供的人工智能生成内容，
                不应视为互联网新闻信息，不能代替专业领域从业人员向您解答对应疑问。
                相关回答基于我们训练时所使用的有限数据，生成的结果供您参考，并不代表 AI 新视界立场。
                如您对技术模型输出的内容有任何反馈建议，欢迎联系我们客服或邮箱：{email_address}
                <br />
                <br />
                再次感谢您的理解和支持，期待与您一起进步。
                <br />
                <br />
                AI 新视界团队<br />
                2023.12.7
                <br />
            </Typography>
        </Modal >
    )
})