import { CopyOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import 'github-markdown-css';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

function CodeBlock({ code, language }) {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        // 创建一个虚拟的textarea元素
        const textarea = document.createElement('textarea');
        textarea.value = code;

        // 将textarea元素追加到DOM中
        document.body.appendChild(textarea);

        // 选择textarea中的文本
        textarea.select();

        try {
            // 尝试复制文本到剪贴板
            if (document.execCommand('copy')) {
                setCopied(true);
                message.success('代码已复制到剪贴板');
            } else {
                // 如果document.execCommand('copy')不支持，或者复制失败，提供备选方案
                // 在这里你可以引导用户手动复制文本
                message.info('请手动复制以下文本：' + code);
            }
        } catch (error) {
            console.error('复制代码失败', error);
            message.error('复制代码失败');
        } finally {
            // 清除创建的textarea元素
            document.body.removeChild(textarea);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <SyntaxHighlighter language={language} style={tomorrow}>
                {code}
            </SyntaxHighlighter>
            <Button
                style={{
                    position: 'absolute',
                    top: '1px',
                    right: '1px',
                    zIndex: 1,
                    opacity: 0.8,
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: 'none',
                }}
                icon={<CopyOutlined />}
                onClick={handleCopy}
            >
                {copied ? '已复制' : '复制代码'}
            </Button>
        </div>
    );
}

export default function MarkdownRenderer({ markdown }) {
    return (
        <ReactMarkdown
            children={markdown}
            components={{
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline && match ? (
                        <CodeBlock
                            code={String(children).replace(/\n$/, '')}
                            language={match[1]}
                            {...props}
                        />
                    ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                    );
                },
            }}
        />
    );
}
