import { Flex, Image, Space } from 'antd';
import police_logo from '../images/police_logo.png';

export default function PoliceComponent() {

    return (
        <Flex align='center' justify='center'>
            <Space align="center" size={1}
            // style={{ border: '1px solid red' }}
            >
                <Image style={{ transform: 'scale(0.5)', padding: 0, margin: 0 }} src={police_logo} preview={false} />
                <div>
                    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50010802005671" rel="noreferrer" target="_blank">渝公网安备50010802005671</a>
                </div>
            </Space>
        </Flex>
    )
}
