import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';  // 引入CSS文件
import App from './App';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN'

// 创建react元素
const root = ReactDOM.createRoot(document.getElementById('root'));
// 渲染react元素
root.render(
  <ConfigProvider locale={zhCN}>
    <App />
  </ConfigProvider>
);