import {
  ArrowDownOutlined,
  ClearOutlined,
  CustomerServiceOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  HeartTwoTone,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  PlusOutlined,
  SendOutlined,
  StarOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined
} from '@ant-design/icons';
import {
  Affix,
  Avatar,
  Button,
  Col,
  Divider,
  Flex,
  FloatButton,
  Form,
  Input,
  Layout,
  List,
  Menu,
  Modal,
  Popconfirm,
  Progress,
  Row,
  Select,
  Tooltip,
  Typography,
  message
} from 'antd';
import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ai_logo from '../images/ai-avatar.png';
import panda from '../images/panda.png';
import { useStore } from "../store";
import { getRequest, postRequest } from "../utils/api";
import { LOGIN_FAILURE } from '../utils/constant';
import { AddChatSessionListByUserId, ClearAllChatInfo, DelelteChatSessionBySessionId, GetChatModelList, GetChatSessionListByUserId, UpdateSessionById } from '../utils/request';
import { ALL_ACCOUNT, FREE_ACCOUNT, divideWithDecimal, getToken, settToken } from '../utils/tools';
import MarkdownRenderer from './MarkdownRenderer';


var { TextArea } = Input;
var { Content, Footer, Header, Sider } = Layout;

const boxStyle = {
  width: '100%',
  height: 100,
};

const defaultSessionName = 'ChatGPT聊天'


// 模板的开始
export const ChatApp = observer(() => {

  var navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('')
  const { chatInfoStore, constantStore } = useStore()
  const [isWindows, setIsWindows] = useState(false);
  const [modelData, setModelData] = useState([]);
  const [inputFlag, setInputFlag] = useState(false)
  const [modalForm] = Form.useForm();
  const [loadings, setLoadings] = useState(false);

  function scrollToBottom() {
    var element = document.getElementById('chat_scrollable_content');
    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth'
    });
  }

  function scrollToTop() {
    var element = document.getElementById('chat_scrollable_content');
    element.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  // 获取数据库model数据列表
  const modelDataFromBackend = async () => {
    try {
      const res = await GetChatModelList();
      console.log(res.data, typeof (res.data));
      setModelData(res.data)
    } catch (error) {
      // 处理错误
      console.error("Error fetching data: ", error);
      throw error; // 可以选择抛出错误以供上游处理
    }
  }




  function handleForm(e) {
    var target = e.target
    var value = target.type === 'checkbox' ? target.checked : target.value
    setSearchValue(value)
  }


  // 向后端发送数据
  function onSearch() {
    const chatSessionId = chatInfoStore.current_chat_session_id
    console.log('chatInfoStore.current_chat_session_id--->', chatSessionId)
    let msg = searchValue.trim()
    console.log('用户输入的值是：', msg)
    if (msg === '') {
      alert('消息不能为空')
      return
    } else if (chatSessionId === null) {
      alert('没有选中会话')
      return
    }

    let data_temp = {
      "user_id": 0,
      "ai_answer": "思考中……",
      "user_question": msg
    }

    let post_data = {
      'msg': msg,
      'chatSessionId': chatSessionId
    }
    // 1、用户选中会话的情况
    if (chatSessionId !== -1) {
      const current_chat_session_info = chatInfoStore.getSessionById(chatSessionId)
      console.log('current_chat_session_info--->', current_chat_session_info)
      if (current_chat_session_info === undefined) {
        // 当前current_chat_session_info错误
        return
      }
      post_data['chatModel'] = current_chat_session_info.model.name
      // post_data['used_times'] = current_chat_session_info.model.used_times
    }
    // 1.1、将临时会话给到当前消息列表
    chatInfoStore.listPush(data_temp)
    // 1.2、输入框不可编辑
    setInputFlag(true)

    // 2、获取后端响应的数据
    postRequest('chatMessage/', post_data).then(res => {
      // 2.1 滚动到底部
      scrollToBottom()
      // 3、赋值
      if (res.success) {
        console.log('openai返回的值：', res.data)
        // 3.1 更新消息列表
        chatInfoStore.listUpdateLastOne(res.data.data)
        // 3.1.1 用户未选中会话的情况
        if (chatSessionId === -1) {
          // 3.1.2 更新左侧session列表
          chatInfoStore.pushToSessionList(res.data.session_info)
          // 3.1.3 更新选中session_id
          chatInfoStore.changeSessionId(res.data.session_info.id)
          // 3.1.4 修改Session标题
          chatInfoStore.setSessionName(res.data.session_info.title)
          chatInfoStore.setSessionModelName(res.data.session_info.model.title)
        }
        // 3.2 更新用户tokens
        settToken(FREE_ACCOUNT, res.data.free_account)
        settToken(ALL_ACCOUNT, res.data.all_account)
        // 3.3 将输入框的值清空
        setSearchValue('')
        // 3.4 滚动到底部
        scrollToBottom()
      } else {
        let data_temp = {
          "user_id": 0,
          "ai_answer": res.error,
          "user_question": msg
        }
        chatInfoStore.listUpdateLastOne(data_temp)
        message.error(res.error)
      }
      // 4、设置输入框为可输入状态
      setInputFlag(false)
    }).then(
      scrollToBottom
    ).catch(reason => {
      console.warn('error reason is ', reason)
      setInputFlag(false)
    })
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey) {
      // Ctrl+Enter键按下，执行换行操作
      e.preventDefault(); // 阻止默认的回车换行行为
      // 获取光标位置
      const cursorPosition = e.target.selectionStart;
      // 将searchValue分为两部分，在光标位置插入换行符
      const newValue =
        searchValue.slice(0, cursorPosition) +
        '\n' +
        searchValue.slice(cursorPosition);
      // 更新TextArea的值
      setSearchValue(newValue);
      // 调整光标位置
      setTimeout(() => {
        e.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
      }, 0);
    } else if (e.keyCode === 13) {
      // 只按下回车键，执行搜索事件
      console.error('===这是电脑端吗？===', isWindows)
      if (isWindows) {
        onSearch();
      } else {  // 手机端
        e.preventDefault();
        setSearchValue(searchValue + '\n')
      }
    }
  }

  // 0、检测当前环境
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isWindows = /windows/i.test(userAgent);
    setIsWindows(isWindows);
  }, []);


  const changeMenuSession = ({ item, key }) => {
    // 阻止相同的chat_session_id触发
    if (chatInfoStore.current_chat_session_id === key) {
      return
    }
    console.log('menu key --->', key)
    const pdata = chatInfoStore.getSessionById(key)
    chatInfoStore.setSessionName(item.props.title)
    chatInfoStore.setSessionModelName(pdata.model.title)
    chatInfoStore.changeSessionId(key)
    console.log('chatInfoStore.current_chat_session_id --->', chatInfoStore.current_chat_session_id)

    // 发送请求获取当前列表
    getRequest('chatMessage/', { chatSessionId: key }).then(
      res => {
        console.log('ai chat后端响应数据--->', res)
        if (res.code === LOGIN_FAILURE) {
          navigate('/login', { replace: true })
        } else if (res.success) {
          chatInfoStore.setCurrentChatInfoList(res.data.chat)
          settToken(FREE_ACCOUNT, res.data.free_account)
          settToken(ALL_ACCOUNT, res.data.all_account)
          console.log(getToken(FREE_ACCOUNT))
          console.log(getToken(ALL_ACCOUNT))
        } else {
          message.error(res.error)
        }
      }).then(
        scrollToBottom
      ).catch((e) => {
        message.error("获取数据失败！", e)
      })
  }

  const addSessionChatEvent = () => {
    AddChatSessionListByUserId().then(res => {
      const new_chat_session = res.data.chat
      // 1、新增后，后端返回
      console.log('chat sessin list--->', new_chat_session)
      // 2、新增后，左侧添加新session对话
      chatInfoStore.pushToSessionList(new_chat_session)
      // 3、新增后，左侧选中当前对话  chatInfoStore.current_chat_session_id
      chatInfoStore.changeSessionId(new_chat_session.id)
      // 4、新增后，选中当前会话名称
      chatInfoStore.setSessionName(new_chat_session.title)
      chatInfoStore.setSessionModelName(new_chat_session.model.title)
      // 5、新增后，清空当前会话内容
      chatInfoStore.listClearAll()
    }).catch((e) => {
      message.error("获取数据失败！", e)
    })
    setLoadings(false)
  }

  const cancel = (key) => {
    console.log('确认删除', key)
    DelelteChatSessionBySessionId({ chatSessionId: key }).then(res => {
      console.log(res)
      // 1、将SessionList中对应的数据删除
      chatInfoStore.deleteSessionById(key)
      // 2、给选中的session设置为默认值
      chatInfoStore.setDefaultValueForSessionId()
      // 3、删除后，赋值为默认seesion——name
      chatInfoStore.setSessionName(defaultSessionName)
      chatInfoStore.setSessionModelName(null)
      // 4、删除后，清空对话信息
      chatInfoStore.listClearAll()
    }).catch((e) => {
      console.error('删除失败--->', e)
    })
  };


  const [showEditDialog, setShowEditDialog] = useState(false);  // 是否显示对话框---编辑会话
  const handleEditCancel = () => {
    console.log('---点击取消---')
    // 取消操作，关闭对话框
    setShowEditDialog(false);
  };

  const handleEditConfirm = async (item) => {
    console.log('---点击确认---')
    const res = await UpdateSessionById(item)
    console.log(res)
    // 更新sessino列表
    if (res.data !== null) {
      chatInfoStore.updateSessionById(res.data)
      chatInfoStore.setSessionName(res.data.title)
      chatInfoStore.setSessionModelName(res.data.model.title)
      setShowEditDialog(false)
      message.success('修改成功')
    }
  }
  const editSession = (item) => {
    console.log('编辑', item.id, item.title, item.model.id, item.model.title)
    // 弹出框框
    setShowEditDialog(true)
    modelDataFromBackend()
  };

  useEffect(() => {
    if (chatInfoStore.chat_session_list.length === 0) {
      GetChatSessionListByUserId().then(res => {
        console.log('chat sessin list--->', res)
        chatInfoStore.setChatSessionList(res.data.chat)
      }).catch((e) => {
        message.error("获取数据失败！", e)
      })
    }
    // eslint-disable-next-line
  }, [])

  const [collapsed, setCollapsed] = useState(false);  // 侧边栏隐藏事件
  const [showClearDialog, setShowClearDialog] = useState(false);
  const handleClearConfirm = () => {
    console.log('---点击确认---')
    const chatSessionId = chatInfoStore.current_chat_session_id
    console.log('chatInfoStore.current_chat_session_id--->', chatSessionId)
    if (chatSessionId === null) {
      alert('请您选择对应会话')
      setShowClearDialog(false)
      return
    } else if (chatInfoStore.current_chat_info_list.length === 0) {
      message.error('没有历史消息')
      setShowClearDialog(false)
      return
    }

    ClearAllChatInfo({ chatSessionId: chatSessionId }).then(res => {
      if (res.code === 503) {
        message.error(res.error)
      } else if (res.code === 200) {
        console.log(res.data)
        // 1、删除成果
        message.success(res.data.message)
        // 2、清除store缓存消息
        chatInfoStore.listClearAll()
      } else {
        message.error('未登录，请先登陆')
        navigate('/login', { replace: true })
      }
      // 3、关闭弹窗
      setShowClearDialog(false)
    }).catch((e) => {
      setShowClearDialog(false)  // 关闭弹窗
      message.error(e)
    })

  }

  const handleButoonClick = () => {
    const pid = chatInfoStore.current_chat_session_id
    console.log('pid--->', pid)
    const data = chatInfoStore.getSessionById(pid)
    if (data === null) {
      message.info('请选择对话')
    } else {
      editSession(data)
    }
  }

  return (
    <Layout>
      <Sider
        style={{ display: collapsed ? 'none' : 'block' }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <Layout style={{ height: '100vh' }}>
          <Header style={{ backgroundColor: 'white', padding: 5 }}>
            <Flex align='center' justify='center' style={{ marginTop: 10 }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                loading={loadings}
                onClick={() => {
                  setLoadings(true)
                  addSessionChatEvent()
                }}
                block
              >
                新对话
              </Button>
            </Flex>
          </Header>
          <Content
            style={{
              backgroundColor: 'white',
              // border: '1px solid red',
              overflow: 'auto',
              margin: 0,
              padding: 5,
              marginBottom: 2,
              marginTop: 2
            }}
            className='scrollable-content'
          >
            <Menu
              defaultSelectedKeys={[]}
              selectedKeys={[`${chatInfoStore.current_chat_session_id}`]}
              onClick={changeMenuSession}
            >
              {
                chatInfoStore.chat_session_list.map(item => (
                  <Menu.Item key={item.id} title={item.title}>
                    <Flex align='center' justify='space-between'>
                      <Flex gap={10}>
                        <span>
                          <MessageOutlined />
                        </span>
                        <span>
                          {item.title.length > 5 ? `${item.title.substring(0, 5)}...` : item.title}
                        </span>
                      </Flex>

                      {chatInfoStore.isEqualSessionId(item.id) && (
                        <Row justify="space-around" align="middle">
                          <Col span={4}>
                            <>
                              <Typography.Link onClick={() => editSession(item)} >
                                <Button type='link' icon={<EditOutlined />} />
                              </Typography.Link>
                              <Modal
                                open={showEditDialog}
                                onOk={() => modalForm.submit()}
                                onCancel={handleEditCancel}
                                title="会话信息"
                                okText="修改"
                                cancelText="取消"
                                destroyOnClose
                                width={400}
                                maskClosable={false}
                              >
                                <Form
                                  style={{
                                    // border: '1px solid red',
                                  }}
                                  form={modalForm}
                                  preserve={false}
                                  onFinish={async (values) => {
                                    values.id = item.id
                                    console.log('表单数据：values--->', values)
                                    handleEditConfirm(values)
                                  }}
                                >
                                  <Form.Item
                                    name="title"
                                    label="名称"
                                    hasFeedback
                                    rules={[
                                      {
                                        required: true,
                                        message: '不能为空!'
                                      }
                                    ]}
                                    initialValue={item.title}
                                  >
                                    <Input style={{ width: '100%', height: 40 }} placeholder='会话标题' />
                                  </Form.Item>
                                  <Form.Item
                                    name="model"
                                    label="模型"
                                    hasFeedback
                                    rules={[
                                      {
                                        required: true,
                                        message: '不能为空!'
                                      }
                                    ]}
                                    initialValue={item.model.id}
                                  >
                                    <Select
                                      style={{ width: '100%', height: 40 }}
                                      placeholder='请选择会话模型'
                                    >
                                      {modelData.map((model) => (
                                        <Select.Option key={model.id} value={model.id}>
                                          {model.title}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>

                                </Form>
                              </Modal>
                            </>
                          </Col>
                          <Col span={4} pull={2}>
                            <Popconfirm title="确定删除此会话?" onConfirm={() => { cancel(item.id) }}>
                              <Button type='link' icon={<DeleteOutlined />} />
                            </Popconfirm>
                          </Col>
                        </Row>
                      )}

                    </Flex>
                  </Menu.Item>
                ))
              }
            </Menu>
          </Content>
          <Footer style={{ backgroundColor: 'white', height: '200px', padding: 10, }}>
            <Flex vertical gap={10} style={{ marginTop: 5 }}>
              <Button type='primary' icon={<HeartTwoTone />}
                onClick={() => {
                  constantStore.setBulletiBoardSwitch(true)
                }}>公告栏</Button>
              <Button type='primary' icon={<HeartTwoTone />}>角色仓库</Button>
            </Flex>
            <Divider style={{ margin: '15px 0px' }} />
            <Flex wrap='wrap' align='center' justify='space-between'>
              <span>余额 {getToken(FREE_ACCOUNT)} ({divideWithDecimal(getToken(FREE_ACCOUNT), getToken(ALL_ACCOUNT))}%)</span>
              <Button type="link" size='small' onClick={() => {
                navigate('/index/paycenter', { replace: true })
              }}>
                充值
              </Button>
            </Flex>
            <Progress percent={divideWithDecimal(getToken(FREE_ACCOUNT), getToken(ALL_ACCOUNT))} />
          </Footer>
        </Layout>
      </Sider>
      <Layout style={{ marginLeft: '2px', height: '100vh' }}>
        <Header
          style={{
            background: 'white',
            flex: 'auto',
            padding: '0',
            // border: '1px solid red',
            maxHeight: '70px'
          }}
        >
          <Flex justify='space-between' align='center'
            style={{
              // border: '2px solid red',
              maxHeight: '70px'
            }}>
            <Flex>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
              <h2 style={{ margin: '0', marginLeft: '15px', fontSize: '17px' }}>
                {chatInfoStore.sessionName}
              </h2>
            </Flex>

            <div>
              <Tooltip placement="bottom" title={"清空对话记录"} >
                <Button
                  disabled={chatInfoStore.current_chat_session_id === -1}
                  style={{
                    border: 'none'
                  }}
                  icon={<ClearOutlined />}
                  onClick={() => { setShowClearDialog(true) }}
                />
              </Tooltip>
              <Tooltip placement="bottom" title={"滚到最底部"}>
                <Button
                  style={{
                    margin: '0px 40px 0px 10px',
                    border: 'none'
                  }}
                  icon={<ArrowDownOutlined />}
                  onClick={scrollToBottom} />
              </Tooltip>
            </div>

            <Modal
              open={showClearDialog}
              onOk={handleClearConfirm}
              onCancel={() => { setShowClearDialog(false) }}
              title="确认删除"
              okText="确认"
              cancelText="取消"
              width={350}
            >
              <p>【{chatInfoStore.sessionName}】聊天信息删除后无法恢复？</p>
            </Modal>
          </Flex>
        </Header>
        <Content
          id='chat_scrollable_content'
          className='scrollable-content'
          style={{
            background: 'white',
            marginTop: '2px',
            marginBottom: '2px',
            // border: '1px solid red',
          }}
        >
          {chatInfoStore.sessionModelName !== null && (
            <Affix offsetTop={66.2}>
              <Flex align='flex-start' justify='center'>
                <Button
                  style={{
                    borderColor: '#f1f1f1',
                    minWidth: '8rem',
                    maxWidth: '15rem',
                    borderStartStartRadius: 0,
                    borderStartEndRadius: 0,
                    borderTop: 'none',
                  }}
                  onClick={handleButoonClick}
                >
                  <Flex horizontal justify='space-around'>
                    <StarOutlined style={{ marginRight: '0.5rem' }} />
                    <span>
                      {chatInfoStore.sessionModelName}
                    </span>
                    <DownOutlined style={{ fontSize: '12px', marginLeft: '0.75rem' }} />
                  </Flex>
                </Button>
              </Flex>
            </Affix>
          )}

          <List
            itemLayout="vertical"
            size="large"
            split={false}
            dataSource={chatInfoStore.current_chat_info_list}
            renderItem={(item) => (
              <List.Item key={item.user_id}
                style={{ overflowX: 'auto', }}
              >
                {/* 用户 */}
                <Flex justify='flex-end' align='flex-start' style={{ marginBottom: 30 }}>
                  <Flex justify='flex-end'
                    style={{
                      width: '60%',
                      height: 'auto',
                    }}>
                    <span style={{
                      borderRadius: 12,
                      background: '#D2F9D1',
                      wordWrap: 'break-word',
                      wordBreak: 'break-all',
                      fontSize: '16px',
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingTop: 15,
                      paddingBottom: 15,
                      marginRight: '10px',
                      overflowX: 'auto',
                      whiteSpace: 'pre-line', // 保留换行符
                    }}>
                      {item.user_question.replace(/ /g, '\u00a0')}
                    </span>
                  </Flex>
                  <Flex>
                    <List.Item.Meta avatar={<Avatar src={panda} />} />
                  </Flex>
                </Flex>

                {/* AI助手 */}
                <Flex justify='flex-start' align='flex-start'
                  style={{
                    marginBottom: 10,
                    // border: '1px solid red', 
                  }}>
                  <Flex>
                    <List.Item.Meta avatar={<Avatar src={ai_logo} />} />
                  </Flex>
                  <Flex style={{
                    width: '80%',
                    height: 'auto',
                  }}>
                    <span
                      style={{
                        borderRadius: 12,
                        background: '#F4F6F8',
                        wordWrap: 'break-word',
                        wordBreak: 'break-all',
                        fontSize: '16px',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        overflowX: 'auto'
                      }}
                    >
                      <MarkdownRenderer markdown={item.ai_answer} />
                    </span>
                  </Flex>
                </Flex>
              </List.Item>
            )}
          />
          <FloatButton.Group
            trigger='hover'
            type="primary"
            icon={<CustomerServiceOutlined />}
            className='float-button-style'
            style={{ marginBottom: 120 }}
          >
            <FloatButton onClick={scrollToTop} icon={<VerticalAlignTopOutlined />} />
            <FloatButton onClick={scrollToBottom} icon={<VerticalAlignBottomOutlined />} />
          </FloatButton.Group>
        </Content>
        <Footer style={{ background: 'white' }}>
          <Flex vertical align='flex-end' style={{
            border: '2px solid #85B8FF',
            borderRadius: '10px'
          }}>
            <Flex style={boxStyle}>
              <TextArea
                placeholder="Send a message."
                count={{
                  show: true,
                  max: 2048,
                }}
                value={searchValue}
                onChange={handleForm}
                readOnly={inputFlag}
                onKeyDown={handleKeyDown}
                style={{
                  resize: 'none',
                  margin: 0,
                  padding: 0,
                  border: 'none', /* 移除边框样式 */
                  outline: 'none', /* 移除聚焦样式 */
                  overflow: 'hidden', /* 隐藏计数器 */
                  borderColor: 'transparent',
                  borderStyle: 'none',
                  borderRadius: 0
                }}
                bordered={false}  /*无边界框*/
              />
            </Flex>
            <Flex style={{
              // border: '2px solid #1677FF',
            }}>
              <Button
                type='ghost'
                size='large'
                onClick={onSearch}
                loading={inputFlag}
                icon={<SendOutlined style={{ fontSize: 22 }} />}
              />
            </Flex>
          </Flex>
        </Footer>
      </Layout>
    </Layout >
  );
})