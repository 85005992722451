import {
  AliwangwangOutlined,
  FileGifOutlined,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { LogoutApp } from '../utils/request';
import { removeAllTokens } from '../utils/tools';
import PayCenter from './PayCenter';
import { UserCenter } from './UserCenter';
import { ChatApp } from './chatApp';
import { SearchImage } from './searchImage';
import { BulletiBoard } from './BulletiBoard';
const { Sider, Content } = Layout;

const setMenuData = [
  {
    label: 'AI 对话',
    key: 'chat',
    icon: <AliwangwangOutlined />,
  },
  {
    label: 'AI 绘画',
    key: 'image',
    icon: <FileGifOutlined />,
  },
  {
    label: '个人中心',
    key: 'user',
    icon: <UserOutlined />,
  },
  {
    label: '退出登录',
    key: 'logout',
    icon: <LogoutOutlined />,
  },
]

/**
 * 查找当前路径选中的menu菜单的值
 * @param key 
 * @returns 
 */
const findOpenKeys = (key) => {
  const result = [];
  const findInfo = (arr) => {
    arr.forEach((element) => {
      if (key.includes(element.key)) {
        result.push(element.key)
        // 判断是否存在子节点
        if (element.children) {
          // 递归调用
          findInfo(element.children)
        }
      }
    });
  }
  findInfo(setMenuData)
  return result
}


export const MenuApp = () => {
  const naviagte = useNavigate();
  // 监听pathname的该变
  const { pathname } = useLocation();
  // 临时选中的值
  const tmpOpenKeys = findOpenKeys(pathname)

  const {
    token: { colorBgContainer },
  } = theme.useToken();


  return (
    <Layout>
      <Sider style={{ background: colorBgContainer }} collapsed={true}>
        <Menu
          style={{ marginTop: 20 }}
          theme='light'
          mode="inline"
          defaultOpenKeys={tmpOpenKeys}  // 展开菜单
          defaultSelectedKeys={tmpOpenKeys}  // 选择菜单
          onClick={({ key }) => {
            if (key === 'logout') {
              LogoutApp().then(res => {
                if (res.success) {
                  removeAllTokens()
                  naviagte('/login', { replace: true })
                }
              })
            } else {
              naviagte(key, { replace: true })
            }
          }}
          items={setMenuData}
        >
        </Menu>
      </Sider>
      <Layout style={{ paddingLeft: '2px' }}>
        <BulletiBoard />
        <Content>
          <Routes>
            <Route key='chat' path='chat' element={<ChatApp />} />
            <Route key='image' path='image' element={<SearchImage />} />
            <Route key='user' path='user' element={<UserCenter />} />
            <Route key='paycenter' path='paycenter' element={<PayCenter />} />
            <Route key='*' path='chat' element={<ChatApp />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};