import axios from "axios";
import { getRequest, postRequest, putRequest } from "./api";
export const ClearAllChatInfo = (data) => putRequest('/clearChatInfo/', data)

export const getUserInfo = () => postRequest('/getUserInfo/')

// 注册or手机号换绑的时候使用的获取验证码
export const getCode = (data) => postRequest('/getCode/', data)
export const changeTelephone = (data) => postRequest('/changeTelephone/', data)
export const updatePwd = (data) => postRequest('/changePwd/', data)


// 支付中心
export const alipay = (data) => postRequest('/alipay/pay/', data)
export const findPayOrder = (data) => getRequest('/pay/order/', data)
export const refreshUserBalance = () => getRequest('getUserInfo/')
export const wechatpay = (data) => postRequest('wechat/pay/create/', data)
export const getGoodsList = () => getRequest('/store/goods/')
export const orderSuccess = (data) => getRequest('/order/success/', data)

// 退出登录
export const LogoutApp = () => postRequest('/logout/')
export const CheckLoginInterface = () => postRequest('/login/check/')

// export const ThirdPartLoginWechat = () => getRequest('/wechat/qr')

// 微信登陆
export const ThirdPartLoginWechat = (callback) => axios.get('/wechat/qr').then((response) => {
    if (callback) {
        callback(response.data)
    }
    return response.data
})
export const ThirdPartLoginReload = (data) => postRequest('/wechat/refresh/', data)
export const ThirdUserRegister = (data) => postRequest('/wechat/register/', data)
export const RegisterCode = (data) => postRequest('/getCode/', data)
export const LoginCode = (data) => postRequest('/getLoginCode/', data)

// 第三方微信公众号登录
export const WeChatPublicLoginRequest = (data) => postRequest('wechat/public/login/', data)
export const WeChatPublicRegister = (data) => postRequest('/wechat/public/register/', data)


// 获取会话列表
export const GetChatSessionListByUserId = () => getRequest('chat/sessionListByUserId/')
export const AddChatSessionListByUserId = () => postRequest('chat/sessionListByUserId/')
export const DelelteChatSessionBySessionId = (data) => putRequest('chat/sessionListByUserId/', data)
export const UpdateSessionById = (data) => postRequest('chat/updateSessionById/', data)
export const GetChatModelList = () => getRequest('chat/chatModel/')

// Discord 画画功能
export const ImagePainterByMidjounery = (data) => postRequest('midjourney/imagine/', data)
export const ImageUpscaleByMidjounery = (data) => postRequest('midjourney/upscale/', data)
export const ImageResetByMidjounery = (data) => postRequest('midjourney/reset/', data)

// TheNextLeg API
export const TheNextLegImagine = (data) => postRequest('thenextleg/imagine/', data)
export const TheNextLegMessage = (data) => postRequest('thenextleg/message/', data)
export const TheNextLegButton = (data) => postRequest('thenextleg/button/', data)
export const TheNextLegDescribe = (data) => postRequest('thenextleg/describe/', data)

// 操作图像信息存储
export const AddImageInfo = (data) => postRequest('image/operateImageInfo/', data)
export const GetImageList = () => getRequest('image/operateImageInfo/')
export const DeleteImageInfo = (data) => putRequest('image/operateImageInfo/', data)

// 翻译功能
export const TranslateIntoEn = (data) => postRequest('translate/gpt/', data)
export const ImageUploadByMidjounery = (data, config) => postRequest('midjourney/upload/', data, config)
export const ImageUploadByTencentSDK = (data, config) => postRequest('tencent/upload/', data, config)
export const ImageUploadByMinioSDK = (data) => postRequest('minio/upload/', data)

// 令牌刷新机制
export const RefreshToken = (data) => postRequest('refresh_token/', data)