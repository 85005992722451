import { message } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NOT_EXIST_USER } from '../utils/constant';
import { WeChatPublicLoginRequest } from '../utils/request';
import { FREE_ACCOUNT, TOKEN, USERNAME, settToken } from '../utils/tools';

export default function WechatPublicLogin() {
    var navigate = useNavigate()

    const location = useLocation()
    // 获取当前页面的 URL
    // console.log('location--->', location)
    const searchParams = new URLSearchParams(location.search);
    // 用户授权码
    const code = searchParams.get('code');

    const fetchData = async (code) => {
        // console.log('code--->', code)
        const res = await WeChatPublicLoginRequest({ 'code': code });
        if (res.success) {
            // 1.1 登录成功
            message.success('登录成功，正在跳转')
            // 1.2 设置存储
            settToken(USERNAME, res.data.username)
            settToken(TOKEN, res.data.token)
            settToken(FREE_ACCOUNT, res.data.free_account)
            // 1.3 路由跳转
            const timer = setTimeout(() => {
                navigate('/index/chat', { replace: true })
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            if (res.code === NOT_EXIST_USER) {
                // 2.1 用户注册界面
                navigate(`/newThirdUserRegister?uuid=${encodeURIComponent(res.uuid)}`, { replace: true })
            } else {
                message.error(res.error)
            }
        }
    }

    if (code !== null && code.trim !== '') {
        fetchData(code)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5%' }}>
            <h1>正在认证授权，code={code}</h1>
        </div>
    )
}
