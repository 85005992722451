import { UserOutlined } from '@ant-design/icons';
import { CheckCard } from '@ant-design/pro-components';
import { Avatar, Button, Card, Divider, Flex, Layout, Modal, Tag, message } from 'antd';
import { useState, useEffect } from 'react';
import { alipay, getGoodsList, wechatpay } from '../utils/request';
import { Alipay, ScreenMinSize, ScreenWidth, Wechat } from '../utils/tools';
import QRCodeComponent from './QRCodeComponent';


export default function PayCenter() {
    const CheckCardAutoWidth = ScreenWidth < ScreenMinSize ? '280px' : '300px'
    const CheckCardAutoHeight = '66px'
    // 用来记录用户选择的套餐价格
    const [goodsList, setGoodsList] = useState([]); // 存储从API获取的套餐数据
    const [selectedGoods, setSelectedGoods] = useState({}); // 存储从API获取的套餐数据
    const [payway, setPayway] = useState(1)
    const [weixinResponse, setWexinResponse] = useState('')
    const [visible, setVisible] = useState(false)

    const show = () => {
        setVisible(true);
    };
    const hide = () => {
        setVisible(false);
    };

    const cancelWeixinPay = () => {
        setWexinResponse('')
    };

    // 点击支付按钮选择支付方式
    const pay = (data) => {
        console.log(data)
        console.log(typeof data)
        setSelectedGoods(data)
        show()
    }

    const onfinish = () => {
        hide()
        /* 调用支付接口 */
        // message.success(`正在来调起支付接口${payway}……`)
        var data = {
            'subject': selectedGoods.title,
            'total_amount': selectedGoods.price,
            'pay_type': payway,
            'goods_id': selectedGoods.id
        }

        console.log(data)

        if (payway === 1) {
            alipay(data).then(res => {
                console.log(res)
                if (res.success) {
                    console.log('支付成功')
                    // 调用支付链接
                    window.open(res.data, '_self');
                }
            }).catch(e => {
                message.error('调用后端支付接口失败！', e)
            })
        } else if (payway === 2) {
            wechatpay(data).then(res => {
                console.log(res)
                if (res.success) {
                    console.log('支付成功')
                    setWexinResponse(res.data)
                    // 返回一个浏览器
                    // window.open(res.data, '_self');
                } else {
                    message.error(res.error)
                }
            }).catch(e => {
                message.error('调用后端支付接口失败！', e)
            })
        } else {
            message.error('该功能尚未开通')
        }

    }

    // 获取套餐数据
    useEffect(() => {
        const fetchPackages = async () => {
            try {
                const response = await getGoodsList() // 替换为实际的API地址
                const goods_list = response.data
                console.log(goods_list)
                setGoodsList(goods_list)
            } catch (error) {
                console.error('Failed to fetch packages', error)
            }
        };

        fetchPackages();
        /*  eslint-disable-next-line */
    }, []); // 空依赖数组表示仅组件挂载时执行
    return (
        <Layout style={{
            // paddingTop: '5rem',
            background: 'white',
            // border: '1px solid red',
            height: '100vh'
        }}>
            <h2 style={{ marginTop: '2rem', marginLeft: '3rem' }}>充值中心</h2>
            <Flex gap={'1rem'}
                style={{ marginTop: '1rem', marginLeft: '2rem' }}
                wrap="wrap"
            >
                {
                    goodsList.map(pkg => (
                        <Card hoverable
                            title={<span style={{ fontSize: '1.25rem', lineHeight: '1.75rem' }}>{pkg.title}</span>}
                            style={{ width: 260 }}
                        >
                            <Flex vertical
                                justify='space-between'
                                style={{ minHeight: '10rem' }}
                                gap={'1rem'}
                            >
                                <Flex vertical gap={'1.0rem'}>
                                    <Flex>
                                        <span style={{ fontSize: '1rem', lineHeight: '1.5rem' }}>价格：</span>
                                        <Tag color={pkg.type === 1 ? "green" : "red"}>{pkg.price} 元</Tag>
                                    </Flex>
                                    <span style={{ minHeight: '2rem' }}>
                                        {/* 原价 198，限时3.5折。 本商品为【Ai 绘图】次数直充，包含200 绘图次数，有效期一年。 */}
                                        {pkg.brief}
                                    </span>
                                </Flex>
                                <Button
                                    onClick={() => {
                                        pay(pkg)
                                    }}
                                    style={{
                                        width: '6.0rem',
                                        height: '2.4rem',
                                        backgroundColor: '#EAF3FF',
                                        color: '#108ee9'
                                    }}>
                                    立即购买
                                </Button>

                            </Flex>
                        </Card>
                    ))
                }
            </Flex>
            {/* <Divider /> */}
            {/* ===========================================================选择支付方式============================================================================== */}


            <Modal
                // title={<p style={{ textAlign: 'center' }}>微信支付</p>}
                destroyOnClose
                footer={null}
                open={weixinResponse !== ''}
                width={'auto'}
                onCancel={cancelWeixinPay}
                maskClosable={false}
                centered
            >
                <QRCodeComponent responseData={weixinResponse} />
            </Modal>
            <Modal
                title={<p style={{ textAlign: 'center' }}>请选择支付方式</p>}
                destroyOnClose
                footer={null}
                open={visible}
                width={355}
                onCancel={hide}
                maskClosable={false}
                centered
            >
                <div style={{ textAlign: 'center' }}>￥ <span style={{ fontSize: 32, fontWeight: 'bold' }}>{selectedGoods.price}</span></div>
                <Divider />
                <div>
                    <CheckCard.Group
                        defaultValue={payway}
                        onChange={(value) => {
                            setPayway(value)
                        }}
                        className='checkCardGroupContainer'
                    >
                        <CheckCard
                            title="支付宝"
                            avatar={
                                <Avatar
                                    style={{ backgroundColor: '#7265e6' }}
                                    src={Alipay}
                                />
                            }
                            style={{ width: CheckCardAutoWidth, height: CheckCardAutoHeight }}
                            value={1}
                        />
                        <CheckCard
                            title="微信"
                            avatar={
                                <Avatar
                                    style={{ backgroundColor: '#7265e6' }}
                                    src={Wechat}
                                />
                            }
                            value={2}
                            style={{ width: CheckCardAutoWidth, height: CheckCardAutoHeight }}
                        />
                        <CheckCard
                            title="银联"
                            avatar={
                                <Avatar
                                    style={{ backgroundColor: '#7265e6' }}
                                    icon={<UserOutlined />}
                                />
                            }
                            value={3}
                            style={{ width: CheckCardAutoWidth, height: CheckCardAutoHeight }}
                        />
                    </CheckCard.Group>
                </div>
                <br />
                <Button block type="primary" onClick={onfinish} style={{ height: 40, width: CheckCardAutoWidth }}>
                    确认支付{selectedGoods.price}元
                </Button>
            </Modal>
        </Layout>
    )
}
