import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    Row,
    message
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { postRequest } from '../utils/api';
import { getVertifyPicture } from '../utils/tools';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export function Register() {
    var navigate = useNavigate(getVertifyPicture())
    const [form] = Form.useForm();

    const toLogin = () => {
        navigate('/login', { replace: true })
    }

    const onFinish = (values) => {
        console.log('用户注册: ', values);
        postRequest('/register/', { msg: values }).then(res => {
            console.log('注册响应：res=', res)
            if (res.code === 200) {
                message.success('注册成功')
                navigate('/login', { replace: true })
            } else {
                message.error(res.error)
            }

        }).catch(e => {
            message.error('注册失败！', e)
        })
    };

    function getCode() {
        // 1、获取当前用户使用的手机号码！
        var tel = form.getFieldValue('user_telephone')
        // 2、发送短信验证码的请求
        postRequest('/getCode/', { msg: tel }).then(res => {
            console.log('注册响应：res=', res)
            if (res.code === 200) {
                message.success('短信息发送成功，请注意查收')
                // form.setFieldValue('code', res.data.msgCode)
                // Validation successful, start countdown and trigger getCode
                setCountdown(60);
            } else {
                message.error(res.error)
            }

        }).catch(e => {
            message.error(e)
        })
    }

    const [countdown, setCountdown] = useState(0);  // 计时器

    // Function to handle button click
    const handleButtonClick = () => {
        form.validateFields(['user_telephone']).then(getCode).catch(error => { console.log(error) });
    };

    // 计时器
    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    return (
        <div align='center' style={{ marginTop: '6%' }}>
            <div style={{ fontSize: '30px', fontWeight: 'bold', textAlign: 'center', marginBottom: 22 }}>
                注册界面
            </div>
            <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                className='layout-register-or-forget-password-form'
                scrollToFirstError
            >

                <Form.Item
                    name="user_account"
                    label="用户名"
                    rules={[
                        {
                            required: true,
                            message: '请输入用户名!',
                        },
                        {
                            min: 4,
                            message: '用户名长度至少为4',
                        }
                    ]}
                    hasFeedback
                >
                    <Input placeholder='请输入用户名' style={{ height: 40 }} />
                </Form.Item>
                <Form.Item
                    name="user_password"
                    label="密码"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码！',
                        },
                        {
                            min: 4,
                            message: '密码长度至少为4',
                        }
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder='请输入密码' style={{ height: 40 }} />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="确认密码"
                    dependencies={['user_password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请确定密码！',
                            min: 1
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('user_password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次输入的密码输入不一致！'));
                            },
                        }),

                    ]}
                >
                    <Input.Password placeholder='请输入密码' style={{ height: 40 }} />
                </Form.Item>

                <Form.Item
                    name="user_telephone"
                    label="手机号"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请输入手机号码!'
                        },
                        {
                            pattern: /^1\d{10}$/,
                            message: '手机号格式错误！',
                        },
                    ]}
                >
                    <Input style={{ width: '100%', height: 40 }} placeholder='请输入手机号码' />
                </Form.Item>

                <Form.Item label="验证码">
                    <Row gutter={8}>
                        <Col span={14}>
                            <Form.Item
                                name="code"
                                noStyle
                                rules={[{ required: true, message: '请输入短信验证码!' }]}
                                hasFeedback
                            >
                                <Input style={{ height: 40 }} />
                            </Form.Item>
                        </Col>
                        <Col offset={2} span={6}>
                            <Button
                                onClick={handleButtonClick}
                                style={{ height: 40 }}
                                disabled={countdown > 0}  // Disable the button if countdown is active
                            >
                                {countdown > 0 ? `${countdown}秒后重试` : '获取验证码'}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject(new Error('请同意授权')),
                        },
                    ]}
                    {...tailFormItemLayout}
                >
                    <Row gutter={8}>
                        <Col span={16}>
                            <Checkbox>
                                I have read the <Link to='/login' onClick={toLogin}>agreement</Link>
                            </Checkbox>
                        </Col>
                        <Col offset={2} span={6}>
                            <Link to='/login' onClick={toLogin}>有账户？</Link>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button block type="primary" htmlType="submit" style={{ height: 40 }}>
                        注册
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};