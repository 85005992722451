export const SUCCESS = 200  // 成功
export const FAILURE = 203  // 失败
export const LOGIN_FAILURE = 403  // 后端查无token
export const METHOD_ERROR = 503  // 请求方式不对
export const NOT_EXIST_USER = 401
export const REGISTER_FAILURE = 603  // 注册失败
export const CODE_FAILURE = 703  // 短信息失败
export const balance_not_enough = 704


export const BIG_IMAGE_SIZE = 1000000       // 检查文件大小 (1MB = 1048576 bytes)
export const COMPRESS_IMAGE_SIZE = 400      // 压缩后大小


export const BUTTON_EVENT_IMAGINE = 'imagine'
export const BUTTON_EVENT_DESCRIBE = 'describe'
export const BUTTON_EVENT_UPSCALE = 'upscale'
export const BUTTON_EVENT_EXPAND = 'expand'

export const USER_IMAGINE_SUCCESS_CLOSE = 1000        // 正常关闭
export const USER_IMAGINE_NOT_TIMES = 3208            // 用户无绘画次数
export const USER_AUTHORIZATION_NOT_EXISTS = 3206     // 请求未携带 token
export const  USER_ACCOUNT_NOT_EXISTS = 3207          // 用户账户不存在
export const USER_JWT_PARSE_FAILURE = 3209            // 令牌解析失败，用户伪造令牌，或令牌过期

export const USER_NEED_TO_LOGIN_CODES = [3206, 3207, 3209]


export const USER_NOT_READER_RULE = 303             // 用户未阅读规则
export const USER_HAD_READER_RULE = 304             // 用户已阅读规则