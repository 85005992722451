import { createContext, useContext } from 'react'

import { ChatImageStore } from './modules/ChatImage'
import { ChatInfoList } from './modules/ChatInfo'
import { ConstantData } from './modules/ConstantData'

class RootState {
    chatImageStore = null
    chatInfoStore = null
    constantStore = null
    constructor() {
        this.chatImageStore = new ChatImageStore()
        this.chatInfoStore = new ChatInfoList()
        this.constantStore = new ConstantData()
    }
}

const rootStore = new RootState()
const context = createContext(rootStore)
const useStore = () => useContext(context)
export { useStore }
